import { Box, Button, Card, CardContent, CircularProgress, IconButton, Sheet, Stack, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

import { API_URL } from "../../api";

export default function BasketItem({ item = {} }) {
    const [ count, setCount ] = useState(item.count);
    const { menu, setMenu } = useContext(LayoutContext);
    const { basket, addBasket, removeBasket } = useContext(LayoutContext);

    const { textTranslate } = useContext(LayoutContext);

    // Удаление товар в корзине
    function deleteBask() {
        const rerer = removeBasket(0, item?.product);
        console.log(basket);        
        // setModalProduct({ ...modalProduct, count: modalProduct?.count - 1 });
    }
    // Уменьшить товар в корзине
    function minusBask() {
        const rerer = removeBasket(item?.count - 1, item?.product);
        console.log(basket);        
        // setModalProduct({ ...modalProduct, count: modalProduct?.count - 1 });
    }
    // Увеличить товар в корзине
    function plusBask() {
        const rerer = addBasket(item?.count + 1, item?.setCount, item?.product);
        console.log(basket);
        // setModalProduct({ ...modalProduct, count: modalProduct?.count + 1 });
    }

    return (
        <Card 
            variant="plain"
            size="sm"
            orientation={"horizontal"}
            sx={{
                p: 0,
                backgroundColor: 'white',
                boxShadow: 'none',
                transition: '.3s ease-in-out',
                transition: 'transform .3s ease-in-out',
                '&:hover': { 
                    transform: 'scale(1.03)', 
                    transition: 'transform .3s ease-in-out',
                    borderColor: 'neutral.outlinedHoverBorder' 
                }
            }}
        >
            <CardContent sx={{ p: 0, height: "100%" }}>
                <Stack
                    direction="row"
                    spacing={0}
                    sx={{
                        height: '100%',
                        justifyContent: "space-between",
                        alignItems: "stretch",
                    }}
                >
                    <Box sx={{
                        minWidth: 130,
                        minHeight: 120,
                        maxWidth: {
                            xs: "30vw"
                        },
                        borderRadius: 'xl',
                        overflow: "hidden",
                        p: 0
                    }}>
                        <img
                            src={ item?.product?.photos_product?.length >= 1 ? item?.product?.photos_product[0]?.photo  : '/default.png' } 
                            
                            loading="lazy"
                            alt=""
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </Box>
                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            width: "100%",
                            p: 1.5,
                            height: 'auto',
                            justifyContent: "space-between",
                            alignItems: "stretch",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                height: '100%',
                                justifyContent: "flex-start",
                                alignItems: "stretch",
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={0}
                                sx={{
                                    // height: '100%',
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography level="title-lg" lineHeight={1.1} sx={{ fontWeight: 'md', textAlign: 'left' }}>
                                    {item?.product?.name}
                                </Typography>
                                <IconButton variant="soft" size="sm" color="danger" onClick={deleteBask}>
                                    <DeleteOutlineRoundedIcon/>
                                </IconButton>
                            </Stack>
                            <Typography level="body-sm" lineHeight={1.1} sx={{ color: "neutral.300", textAlign: 'left' }}>
                                {item?.product?.description}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                // height: '100%',
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography level="title-lg" color="primary" sx={{ textAlign: 'left', width: "100%", height: 'auto', verticalAlign: '' }}>
                                {Math.ceil(item?.product?.price)} රු
                            </Typography>
                            
                            
                            <Stack
                                direction="row"
                                spacing={0}
                                sx={{
                                    justifyContent: { xs: 'center', sm: 'flex-end' },
                                    alignItems: "stretch",
                                }}
                            >
                                <IconButton variant="soft" color="primary" size="sm" onClick={() => minusBask()} sx={{ borderEndEndRadius: '0', borderTopRightRadius: '0' }}>
                                    <RemoveRoundedIcon />
                                </IconButton>
                                <Sheet variant="soft" color="primary" size="sm"
                                    sx={(theme) => ({
                                        py: 0,
                                        fontSize: 'md',
                                        px: 1.5,
                                        textAlign: "center",
                                        height: 'auto',
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // backgroundColor: "primary.800",
                                        borderRadius: '0',
                                        [theme.breakpoints.down('sm')]: {
                                            // width: 'auto'
                                        }
                                    })}
                                >
                                    {item?.count}
                                </Sheet>
                                <IconButton variant="soft" color="primary" size="sm" onClick={() => plusBask()} sx={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}>
                                    <AddRoundedIcon />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}