import React, { useCallback, useContext, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';



// Импорт изображения маркера
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';
import { Stack, Tab, tabClasses, TabList, Tabs, Typography, useTheme } from '@mui/joy';
import { LayoutContext } from '../../context';


export default function MapBlockCompany({ latitude, longitude }) {
    const theme = useTheme();
    const { textTranslate } = useContext(LayoutContext);
    const [position, setPosition] = useState([latitude, longitude]); // Координаты Шри-Ланки, Коломбо
    const [address, setAddress] = useState("");

    const [ satelliteView, setSatelliteView ] = useState(0);

    // Настройка иконки маркера вручную
    const customIcon = new L.Icon({
        iconUrl: markerIconPng,
        shadowUrl: markerShadowPng,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    // Компонент для обработки кликов по карте
    // function LocationMarker() {
    //     useMapEvents({
    //     click: async (e) => {
    //         const { lat, lng } = e.latlng;
    //         setPosition([lat, lng]);

    //         // Выполнить обратное геокодирование для получения адреса
    //         try {
    //         const response = await axios.get(
    //             `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
    //         );
    //         if (response.data && response.data.display_name) {
    //             setAddress(response.data.display_name);
    //         }
    //         } catch (error) {
    //         console.error("Ошибка при получении адреса:", error);
    //         }
    //     },
    //     });

    //     return position === null ? null : (
    //         <Marker position={position} icon={customIcon}></Marker>
    //     );
    // }

    return (
        <Stack spacing={1}>
            <Typography sx={{ mb: 0 }} level='body-sm' color='primary'>
                {textTranslate.map_view}
            </Typography>
            <Tabs
                aria-label="tabs"
                defaultValue={satelliteView}
                sx={{ width: '100%', bgcolor: 'transparent' }}
                onChange={(e, n) => setSatelliteView(n)}
            >
                <TabList
                    disableUnderline
                    sx={{
                        width: 'min-content',
                        [theme.breakpoints.down('sm')]: {
                            width: '100%'
                        },
                        [theme.breakpoints.up('md')]: {
                            width: '100%'
                        },
                        p: 0.5,
                        gap: 0.5,
                        borderRadius: 'xl',
                        bgcolor: 'background.level1',
                        [`& .${tabClasses.root}[aria-selected="true"]`]: {
                            boxShadow: 'sm',
                            bgcolor: 'background.surface',
                        },
                    }}
                >   
                    <Tab disableIndicator value={0} sx={{
                        [theme.breakpoints.down('sm')]:{
                            width: '100%'
                        },
                        [theme.breakpoints.up('md')]:{
                            width: '100%'
                        },
                    }}>
                        {textTranslate.scheme}
                    </Tab>
                    <Tab disableIndicator value={1} sx={{
                        [theme.breakpoints.down('sm')]:{
                            width: '100%'
                        },
                        [theme.breakpoints.up('md')]:{
                            width: '100%'
                        },
                    }}>
                        {textTranslate.satellite}
                    </Tab>
                </TabList>
            </Tabs>

            <MapContainer
                center={position}
                zoom={15}
                style={{ height: '150px', width: '100%', borderRadius: '12px' }}
            >
                { satelliteView == 0 ?
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    :
                    <TileLayer
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        attribution='<a href="https://www.esri.com/">Esri</a>, NASA, USGS'
                    />
                }
                {/* <LocationMarker /> */}
                <Marker position={position} icon={customIcon}></Marker>
            </MapContainer>
        </Stack>
    );
}




// export default function MapBlockCompany({ latitude, longitude }) {
//     // Изначальные координаты центра карты
//     const [mapCenter, setMapCenter] = useState([latitude, longitude]);
//     const [placemarkCoords, setPlacemarkCoords] = useState([latitude, longitude]); // Сначала метка не отображается

//     // Обработчик клика по карте
//     const onMapClick = (e) => {
//         const newCoords = e.get('coords');
//         setPlacemarkCoords(); // Устанавливаем координаты метки в точку клика
//         console.log('Выбранные координаты:', newCoords); // Можно сохранить или отправить на сервер
//     };

//     return (
//         <Box sx={{ height: 200, width: '100%', borderRadius: '12px', overflow: 'hidden' }}>
//             <YMaps query={{ apikey: '924086eb-390b-4b7e-90c8-319d6514f70b' }}>
//                 <Map
//                     width="100%"
//                     height="100%"
//                     // type: 'yandex#satellite'
//                     defaultState={{ center: mapCenter, zoom: 15 }}
//                     onClick={onMapClick} // Добавляем обработчик клика по карте
//                 >
//                     <GeolocationControl options={{ float: "left" }} />
//                     <RulerControl options={{ float: "right" }} />
//                     <ZoomControl options={{ float: "right" }} />
//                     <TypeSelector options={{ float: "right" }} />
//                     <Placemark
//                         geometry={placemarkCoords}
//                         options={{ draggable: true }} // Разрешаем перетаскивание метки
//                         onDragEnd={(e) => {
//                             const newCoords = e.get('target').geometry.getCoordinates();
//                             setPlacemarkCoords(newCoords); // Обновляем координаты при перетаскивании
//                         }}
//                     />
//                 </Map>
//             </YMaps>
//         </Box>
//     );
// }