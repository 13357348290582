import React, { useContext, useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

// import required modules
import { EffectCoverflow, Autoplay } from 'swiper/modules';
import { Box, Card, CardContent, CardCover, Chip, Divider, Stack, Typography } from '@mui/joy';
import { APIGetBanners, APIGetShopInfo } from '../../api';
import { LayoutContext } from '../../context';
import { DataContext } from '../../contextData';


export default function SliderBanner() {
    const { language, setModalCompany, setModalCompanyOpen } = useContext(LayoutContext);
    const { dataMainBanners } = useContext(DataContext);

    function toCompany(shop_id) {
        APIGetShopInfo(shop_id, true, language).then((data) => {
            if (data) {
                setModalCompany(data);
                setModalCompanyOpen(true);
            }
        });
    }

    return (
        <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                stretch: 10,
                slideShadows: false,
            }}
            autoplay={{
                delay: 3000
            }}
            modules={[ EffectCoverflow, Autoplay ]}
            className="mySwiper"
            style={{ margin: '20px 0' }}
        >
            {
                dataMainBanners?.map((item) => ( 
                    <SwiperSlide style={{ width: 'auto' }} key={item.id}>
                        <Card onClick={() => item?.shop?.id ? toCompany(item?.shop?.id) : undefined} component="li" sx={{ cursor: item?.shop?.id ? "pointer" : "auto", width: '80vw', maxWidth: 400, height: 120, flexGrow: 1, borderRadius: 'sm' }} variant="plain">
                            <CardCover>
                                <img
                                    src={item.photo}
                                    loading="lazy"
                                    alt=""
                                    style={{ objectFit: 'cover' }}
                                />
                            </CardCover>
                            <CardCover
                                sx={{ background: 'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 300px)' }}
                            />
                            <CardContent sx={{ justifyContent: 'flex-end' }}>
                                <Stack direction={'row'} sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                    <Box>
                                        <Typography
                                            level="body-lg"
                                            textColor="#fff"
                                            sx={{ fontWeight: 'lg', textAlign: 'left' }}
                                        >
                                            {item.offer}
                                        </Typography>
                                        <Typography
                                            level="body-sm"
                                            textColor="#fff"
                                            sx={{ fontWeight: 'xs', textAlign: 'left' }}
                                        >
                                            {item.description}
                                        </Typography>
                                    </Box>
                                    { item?.shop?.id && (
                                        <LaunchRoundedIcon fontSize='xl2' sx={{ color: 'neutral.100' }}/>
                                    )}
                                    
                                </Stack>
                            </CardContent>
                        </Card>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}