import React, { useContext, useMemo } from "react";
import { Box, Container, Link, Stack, Typography } from "@mui/joy";

import LaunchIcon from '@mui/icons-material/Launch';
import { LayoutContext } from "../../context";

export default function Footer() {
    const { textTranslate, user, language } = useContext(LayoutContext);


    return (
        <Box>
            <Box style={{ backgroundColor: '#1a1a1a', padding: '10px 0' }}>
                <Container sx={{ height: '100%' }}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={{ xs: 2, sm: 3 }}
                >
                        <Typography textColor={'neutral.300'} sx={{ fontSize: { xs: 'sm', sm: 'md'} }}>
                            {textTranslate.developer}: <Link href={'https://t.me/kiryushka01'} fontWeight={'xl'} color="neutral" textColor={'neutral.100'} endDecorator={<LaunchIcon/>}> @kirill_skryabin</Link>
                        </Typography>
                    </Stack>
                </Container>
            </Box>
        </Box>
    )
}