import { AnimatePresence, motion } from 'framer-motion';
import { Modal, Box, Button, Stack, Typography, IconButton, Input, Divider } from '@mui/joy';
import { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../context';
import BasketItem from './BasketItem';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AddBoxRounded } from '@mui/icons-material';
import InputPhone from '../InputPhone/InputPhone';


export default function ModalBasket() {
    const { modalBasketOpen, setModalBasketOpen, basket, setBasket } = useContext(LayoutContext);
    const { modalOrderCreate, setModalOrderCreate } = useContext(LayoutContext);
    const { textTranslate } = useContext(LayoutContext);


    useEffect(() => {
        if (modalBasketOpen | modalOrderCreate) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
        // Очистка при размонтировании компонента
        return () => document.body.style.overflow = 'auto';
    }, [modalBasketOpen, modalOrderCreate]);

    const [ totalPrice, setTotalPrice ] = useState(0);
    useEffect(() => {
        var sum = 0;
        basket?.map((item) => (
            sum += item.product.price * item.count
        ))
        setTotalPrice(sum);
        if (basket?.length <= 0) setModalBasketOpen(false);
    }, [basket])
    
    return (
        <AnimatePresence>
            {modalBasketOpen && (
                <>
                    {/* Затемняющий фон */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backdropFilter: 'blur(10px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 10,
                        }}
                    />
                    <motion.div
                        initial={{ y: '100%', opacity: 0  }} // Начальное положение и прозрачность
                        // animate={{ y: 0, opacity: 1 }} // Анимация открытия
                        animate={modalOrderCreate ? { scale: 0.9, y: 0, borderRadius: '25px' } : { y: 0, opacity: 1 }}
                        exit={{ y: 400, opacity: 0 }} // Анимация закрытия
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '100%',
                            backgroundColor: '#f3f3f3',
                            zIndex: 11,
                            overflow: 'auto',
                          }} // Длительность анимации
                    >
                        <Box
                            sx={{
                                m: 2,
                                mb: 4
                            }}
                        >
                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={{ xs: 1, sm: 2 }}
                                sx={{
                                    my: 1, mb: 2
                                }}
                            >
                                <Typography level='h2' sx={{ mb: 2 }}>
                                    {textTranslate.basket}
                                </Typography>
                                <Stack
                                    direction='row'
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={{ xs: 1, sm: 2 }}
                                    sx={{
                                        my: 1,
                                    }}
                                >
                                    <IconButton variant="soft" color="danger" onClick={() => setModalBasketOpen(false)} sx={{ height: 'fit-content' }}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Divider/>

                            <Stack
                                justifyContent="space-between"
                                alignItems="stretch"
                                spacing={2}
                                direction={{ xs: 'column', sm: 'row' }}
                                sx={{ my: 2 }}
                            >
                                { basket.length >= 1 ?
                                    basket?.map((item) => ( 
                                        <BasketItem key={item?.product?.id} item={item}/>
                                    ))
                                    :
                                    undefined
                                }
                            </Stack>
                            
                            

                            <Stack
                                justifyContent="space-between"
                                alignItems="stretch"
                                spacing={{ xs: 1, sm: 2 }}
                                direction={{ xs: 'column', sm: 'row' }}
                                sx={(theme) => ({
                                    p: 2,
                                    mb: 2,
                                    zIndex: 2,
                                    boxShadow: 'md',
                                    backdropFilter: 'blur(10px)',
                                    backgroundColor: theme.vars.palette.primary[1000],
                                    position: 'sticky', bottom: 30,
                                    borderRadius: 'md',
                                })}
                            >   
                                <Stack
                                    direction='row'
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={4}
                                >
                                    
                                    <Stack
                                        sx={{ height: '100%', width: '100%' }}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        spacing={0.5}
                                    >
                                        <Box>
                                            <Typography level='h3' sx={(theme) => ({ color: theme.vars.palette.neutral[50] })}>
                                                {textTranslate.conclusion}:
                                            </Typography>
                                        </Box>
                                        <Typography fontWeight="xl" level="h3" sx={(theme) => ({ color: theme.vars.palette.primary[800] })}>
                                            {Math.ceil(totalPrice)} රු
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Button 
                                    sx={{ 
                                        height: 40,
                                        fontSize: 'md', 
                                        width: { xs: '100%', lg: 'auto' }
                                    }}
                                    onClick={() => setModalOrderCreate(true)}
                                >
                                    {textTranslate.submit}
                                </Button>
                            </Stack>
                        </Box>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}