import { Grid, Typography, Stack } from "@mui/joy";
import CategoryItem from "./CategoryItem";
import { useContext } from "react";
import { LayoutContext } from "../../context";
import { DataContext } from "../../contextData";




export default function Category() {
    const { textTranslate } = useContext(LayoutContext);
    const { listCategories } = useContext(DataContext);
    
    return (
        <Grid 
            container 
            spacing={{ xs: 2 }}
            columns={{ xs: 4, sm: 8, md: 10 }}
            sx={{ flexGrow: 1 }}
        >
            {  listCategories?.length > 0 ?
                listCategories.map((category) => (
                    <Grid item xs={2} sm={4} md={5} key={category.id}>
                        <CategoryItem item={category}/>
                    </Grid>
                ))
                :
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{ width: "100%" }}
                >
                    <img src='/shark_load.gif' height={150} style={{ borderRadius: '20px', objectFit: 'cover' }} />
                    <Typography sx={{ backgroundColor: 'neutral.50', py: 1, px: 1.5, borderRadius: 'md', textAlign: 'center' }}>
                        {textTranslate.loading} ...
                    </Typography>
                </Stack>
            }
        </Grid>
    )
}