import React, { createContext, useEffect, useState } from 'react';
import texts from './texts';
import { translateText, translateTextObject } from './utils';
import { APICreateOrder, APIGetUser } from './api';
import { useTheme } from '@mui/joy';


// Создаем контекст с начальным значением
export const LayoutContext = createContext();


// Создаем провайдер, который будет оборачивать все компоненты
export const LayoutProvider = ({ children }) => {
    // const [ theme, setTheme ] = useState('light');

    const [ loadLang, setLoadLang ] = useState(false);
    const [ language, setLanguage ] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
    const [ textTranslate, setTextTranslate ] = useState(texts);

    const [ user, setUser ] = useState({});
    const [ modalProductOpen, setModalProductOpen ] = useState(false);
    const [ modalProduct, setModalProduct ] = useState({});

    const [ modalAccountOpen, setModalAccountOpen ] = useState(false);
    
    const [ modalBasketOpen, setModalBasketOpen ] = useState(false);
    const [ basket, setBasket ] = useState([]);

    const [ modalOrderCreate, setModalOrderCreate ] = useState(false);
    
    const [ modalCompany, setModalCompany ] = useState({});
    const [ modalCompanyOpen, setModalCompanyOpen ] = useState(false);

    const [ modalOrders, setModalOrders ] = useState(false);

    const [ webApp, setWebApp ] = useState(window?.Telegram?.WebApp);


    // -=-=- Загрузка WebApp -=-=-
    useEffect(() => {
      setWebApp(window.Telegram.WebApp);
      // Сообщаем Telegram, что WebApp готово
      webApp?.ready();
      // Разворачиваем WebApp на весь экран
      webApp?.expand();
      // отключить вертикальный свайп
      webApp?.disableVerticalSwipes();
    }, []);
    // -=-=-=-=-=-=-=-=-=-=-=-

    // Функция для переключения темы
    // const toggleTheme = () => {
    //   setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    // };


    // Загрузка пользователя
    useEffect(() => {
      if (!user?.id && user?.token) {
        APIGetUser(user?.token).then((data) => {
          if (data) {
            setUser({...user, ...data});
            setLanguage(data.lang);
          }
        });
      }
    }, [user]);

    
    // Добавить элмент в корзину
    const addBasket = (count, setCount, product) => {
      var basks = basket;
      const bask_id = basket.findIndex((element) => element.product.id === product.id)
      if (bask_id > -1) {
          basks[bask_id].count = count;
          setBasket([...basks]);
      }
      else {
          const newBask = {product: product, count: 1, setCount: setCount};
          setBasket([...basket, newBask]);
      }

      return count;
    }

    // Уменьшить элемент в корзине
    const removeBasket = (count, product) => {
        var basks = basket;
        
        const bask_id = basket.findIndex((element) => element.product.id === product.id)
        if (bask_id > -1)
            if (count < 1) basks = basks.filter(p => p.product.id !== basks[bask_id].product.id);
            else basks[bask_id].count = count;
            setBasket([...basks]);

        return count;
    }

    useEffect(() => {
      if (modalBasketOpen) {
        webApp?.MainButton?.hide();
      }
      else if (basket?.length < 1) {
        webApp?.MainButton?.hide();
      }
      else {
        // Настраиваем главную кнопку
        // webApp?.MainButton?.color(theme.colorSchemes.light.palette);  // Устанавливаем текст кнопки
        webApp?.MainButton?.setText(textTranslate?.basket);  // Устанавливаем текст кнопки
        webApp?.MainButton?.show();  // Отображаем кнопку
        
        // Делаем кнопку активной
        webApp?.MainButton?.enable();

        // Обработка нажатия на главную кнопку
        webApp?.onEvent('mainButtonClicked', function() {
          setModalBasketOpen(true);
        });
      }
    }, [basket, modalBasketOpen, webApp])

    // Отслеживаем изменение текстов
    // useEffect(() => {
    //   localStorage.setItem('language', language);
    // }, [textTranslate]);

    // Если происходит смена языка
    useEffect(() => {
      const loadTranslations = async () => {
        console.log('aaaaa');
        console.log('Lang', localStorage.getItem('language'));
        console.log('Lang2', language);
        console.log(localStorage.getItem('language') == language);
        console.log(localStorage.getItem('languageTranslate'));
        
        localStorage.setItem('language', language);
        setTextTranslate(texts[language]);
        // Настраиваем главную кнопку
        webApp?.MainButton?.setText(texts[language].basket);
      };
      loadTranslations();
    }, [language]);


    const _values = {
        // theme, toggleTheme,
        user, setUser,
        addBasket, removeBasket,

        modalProductOpen, setModalProductOpen,
        modalProduct, setModalProduct,
        modalAccountOpen, setModalAccountOpen,
        modalBasketOpen, setModalBasketOpen,
        basket, setBasket,
        modalOrderCreate, setModalOrderCreate,
        
        modalCompany, setModalCompany,
        modalCompanyOpen, setModalCompanyOpen,

        modalOrders, setModalOrders,
        webApp, setWebApp,

        loadLang, setLoadLang,
        language, setLanguage,
        textTranslate, setTextTranslate,
    }
  
    return (
      <LayoutContext.Provider value={_values}>
        {children}
      </LayoutContext.Provider>
    );
};