import { Box, Container, Grid, Typography } from "@mui/joy";
import Header from "../UI/header/Header";
import ChipSlider from "../components/ChipSlider/ChipSlider";
import SliderBanner from "../components/Banners/SliderBanner";
import ItemProduct from "../components/ItemProduct/ItemProduct";
import Category from "../components/Catagory/Category";
import { Link as LinkRouter, useLocation } from "react-router-dom";
import SearchHeader from "../UI/searchHeader/SearchHeader";
import ModalProduct from "../components/ModalProduct/ModalProduct";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { EffectCoverflow, Autoplay } from 'swiper/modules';

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useContext, useEffect, useMemo, useState } from "react";
import { LayoutContext } from "../context";
import { APIGetProductsCategory, APIGetProductsGroup, APIGetRandomTypeGroupProducts } from "../api";
import { DataContext } from "../contextData";


export default function MainPage() {
    const { textTranslate, language } = useContext(LayoutContext);
    const { mainPageListPodbor } = useContext(DataContext);

    return (
        <Box sx={{ mb: 4 }}>
            <SliderBanner/>
                <Container maxWidth="sm" sx={{ my: 3 }}>
                    <Typography level="h3" mb={2}>
                        {textTranslate.categories}
                    </Typography>
                <Category/>
            </Container>
            
            { mainPageListPodbor?.map((item) => (
                <Container maxWidth="sm" sx={{ my: 2 }}>
                    {/* <LinkRouter to={`/category/${topProduct[0]?.shop?.category_store?.tag_slug}`} style={{ textDecoration: 'none', width: '100%' }}> */}
                        <Typography level="h4" color="primary" mb={2} sx={{ width: '100%', justifyContent: 'space-between' }}>
                            {item.name}
                        </Typography>
                    {/* </LinkRouter> */}
                    <Swiper
                        grabCursor={true}
                        spaceBetween={20}
                        slidesPerView={2}
                        autoplay={{
                            delay: 8000
                        }}
                        modules={[ Autoplay ]}
                        className="SwiperItem"
                        style={{ padding: '5px', width: 'auto' }}
                    >
                        {
                            item?.products?.map((product) => ( 
                                <SwiperSlide style={{ width: 'auto' }} key={product.id}>
                                    <ItemProduct orientation={"vertical"} item={product} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </Container>
            ))}
            
            
        </Box>
    )
}