import { Button, FormControl, IconButton, Input, Stack } from "@mui/joy";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { LayoutContext } from "../../context";
import { useContext, useState } from "react";
import classes from "./SearchHeader.module.css";


export default function SearchHeader() {
    const { textTranslate } = useContext(LayoutContext);
    const location = useLocation();
    const navigate = useNavigate();

    // Проверка, на главной ли странице
    const isHomePage = location.pathname !== '/';

    const [searchParams, setSearchParams] = useSearchParams();
    // Получение параметра из URL
    const q = searchParams.get('q'); // Получаем значение 'param'
    const [ paramQ, setParamQ ] = useState(q);

    function setParamQs() {
        if (!paramQ) searchParams.delete('q'); // Удаляем параметр
        else searchParams.set('q', paramQ); // Добавляем параметр
        navigate(`/search?${searchParams.toString()}`, { replace: location.pathname !== '/search' ? false : true });
    }
    
    return (
        <FormControl>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    width: '100%',
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                { isHomePage ?
                    <IconButton color="primary" level="title-lg" underline="none" onClick={() => navigate(-1)}>
                        <ArrowBackIosRoundedIcon/>
                    </IconButton>
                    :
                    undefined
                }
                <Input
                    color="neutral" variant="plain"
                    // startDecorator={<SearchRoundedIcon/>}
                    placeholder={textTranslate.search}
                    // endDecorator={
                    //     <Button sx={{ px: 1.5, py: 0.3, my: 0.5 }}>
                    //         <SearchRoundedIcon fontSize="xl2" /> Найти
                    //     </Button>
                    // }
                    value={paramQ} 
                    onChange={(e) => setParamQ(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" ? setParamQs() : undefined}
                    sx={{
                        // "--Input-minHeight": "40px",
                        backgroundColor: 'white',
                        width: '100%',
                        // '--Input-focusedInset': 'var(--any, )',
                        // '--Input-focusedHighlight': 'rgba(13,110,253,.25)',
                        // '&::before': {
                        //     transition: 'box-shadow .15s ease-in-out',
                        // },
                        // '&:focus-within': {
                        //     borderColor: '#86b7fe',
                        // },
                    }}
                />
                <Button
                    sx={{ p: 0 }}
                    onClick={setParamQs}
                    className={`${classes.search_button} ${paramQ ? classes.visible : ""}`}
                >
                    <SearchRoundedIcon fontSize="xl2" /> {textTranslate.searchLook}
                </Button>
            </Stack>
        </FormControl>
    )
}