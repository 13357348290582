import { Box, Button, Container, Grid, Link, Stack, Typography } from "@mui/joy";
import Header from "../UI/header/Header";
import ChipSlider from "../components/ChipSlider/ChipSlider";
import SliderBanner from "../components/Banners/SliderBanner";
import ItemProduct from "../components/ItemProduct/ItemProduct";
import Category from "../components/Catagory/Category";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { LayoutContext } from "../context";
import { useContext, useEffect, useState } from "react";
import { APIGetProductsCategory, APIGetProductsGroup, APITypeGroupsByCategory } from "../api";
import Footer from "../UI/footer/Footer";


export default function CategoryPage() {
    const { slugID } = useParams(); // захватываем раздел из URL

    // Получаем значение 'slugGroup' из URL
    const [ searchParams, setSearchParams ] = useSearchParams();
    const paramSlugGroup = searchParams.get('slugGroup');

    const { user, textTranslate, language } = useContext(LayoutContext);

    const [ typeGroups, setTypeGroups ] = useState([]);
    const [ products, setProducts ] = useState([]);
    const [ loadingProducts, setLoadingProducts ] = useState(false);


    function UpdateGroups() {
        APITypeGroupsByCategory(slugID, true, language).then((data) => {
            if (data) {
                console.log('GetTypeGroups', data)
                setTypeGroups(data);
            }
        });
    }
    function UpdateProductsCat() {
        APIGetProductsGroup({ type_group_slug: paramSlugGroup, limit: -1, lang: language }).then((data) => {
            if (data) {
                console.log('setProducts', data)
                setProducts([{ group: undefined, data: data }]);
                setLoadingProducts(false);
            }
        })
    }
    useEffect(() => {
        setLoadingProducts(true);
        UpdateGroups();
        UpdateProductsCat();
    }, []);


    function SlugListProduct(update) {
        // Если выбрана какая то группа
        if (paramSlugGroup) {
            if (!update && products?.map((itm) => itm.group)?.includes(paramSlugGroup)) return
            setLoadingProducts(true);
            APIGetProductsGroup({ type_group_slug: paramSlugGroup, limit: -1, lang: language }).then((data) => {
                if (data) {
                    if (update) setProducts([{ group: paramSlugGroup, data: data }])
                    else setProducts([...products, { group: paramSlugGroup, data: data }])
                    console.log("SLD", data);
                    setLoadingProducts(false);
                }
            });
        }
        // Если все товары категории
        else {
            APIGetProductsCategory(slugID, language).then((data) => {
                if (data) {
                    if (update) setProducts([{ group: paramSlugGroup, data: data }])
                    else setProducts([...products, { group: paramSlugGroup, data: data }])
                    setLoadingProducts(false);
                }
            });
        }
    }

    // Обновление товаров при смене языка
    useEffect(() => {
        setLoadingProducts(true);
        UpdateGroups();
        SlugListProduct(true);
    }, [language]);
    // Меняем список товаров при изменении параметра раздела товраов
    useEffect(() => {
        console.log("FFFF");
        // setLoadingProducts(true);
        SlugListProduct(false);
    }, [searchParams]);
    


    return (
        <Box sx={{ minHeight: "86.2vh", display: 'flex', justifyContent: 'space-between', flexDirection: "column" }}>
            <Box sx={{ mb: 3 }}>
                <ChipSlider categoryID={slugID} typeGroups={typeGroups} />
                <Container maxWidth="sm" sx={{ my: 1 }}>
                    <Typography level="h2" mb={2}>
                        { !paramSlugGroup ?
                            textTranslate.all
                            :
                            typeGroups.find(group => group.tag_slug === paramSlugGroup)?.name
                        }
                    </Typography>
                    { !loadingProducts ?
                        products?.find(obj => obj?.group === paramSlugGroup)?.data?.length >= 1 ?
                            <Grid
                                container 
                                spacing={{ xs: 2 }}
                                columns={{ xs: 2, sm: 8, md: 10 }}
                                sx={{ flexGrow: 1 }}
                            >
                                {products?.find(obj => obj?.group === paramSlugGroup)?.data?.map((product) => (
                                    <Grid item xs={2} sm={4} md={5} key={product.id}>
                                        <ItemProduct orientation={'horizontal'} item={product} />
                                    </Grid>
                                ))}
                            </Grid>
                            :
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                            >
                                <img src='/yH.gif' height={150} style={{ borderRadius: '20px', objectFit: 'cover' }} />
                                <Typography level="h4" color="primary" sx={{ backgroundColor: 'neutral.50', py: 1, px: 1.5, borderRadius: 'md', textAlign: 'center' }}>
                                    {textTranslate.list_none}
                                </Typography>
                            </Stack>
                        :
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <img src='/shark_load.gif' height={150} style={{ borderRadius: '20px', objectFit: 'cover' }} />
                            <Typography sx={{ backgroundColor: 'neutral.50', py: 1, px: 1.5, borderRadius: 'md', textAlign: 'center' }}>
                                {textTranslate.loading} ...
                            </Typography>
                        </Stack>
                        
                    }
                </Container>
            </Box>
            <Footer/>
        </Box>
    )
}