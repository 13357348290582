import { AspectRatio, Box, Button, ButtonGroup, DialogContent, IconButton, Link, Modal, ModalClose, ModalDialog, ModalOverflow, Sheet, Skeleton, Stack, Typography, useTheme } from "@mui/joy";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Transition } from "react-transition-group";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

import { LayoutContext } from "../../context";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import SliderDemonstProduct from "./SliderDemonstProduct";
import { APIGetShopInfo } from "../../api";


function ModalProduct ({ }) {
    // const [ count, setCount ] = useState(0);
    console.log('Modal');
    
    const { basket, addBasket, removeBasket } = useContext(LayoutContext);
    const { modalProductOpen, setModalProductOpen, setModalCompanyOpen, setModalCompany } = useContext(LayoutContext);
    const { modalProduct, setModalProduct, textTranslate, language } = useContext(LayoutContext);


    function toCompany() {
        APIGetShopInfo(modalProduct?.product?.shop?.id, true, language).then((data) => {
            if (data) {
                setModalProductOpen(false);
                console.log('SHOP', data)
                setModalCompany(data);
                setModalCompanyOpen(true);
            }
        });
    }

 
    // Уменьшить товар в корзине
    function minusBask() {
        const rerer = removeBasket(modalProduct?.count - 1, modalProduct?.product);
        console.log(basket);
        setModalProduct({ ...modalProduct, count: modalProduct?.count - 1 });
    }
    // Увеличить товар в корзине
    function plusBask() {
        const rerer = addBasket(modalProduct?.count + 1, modalProduct?.setCount, modalProduct?.product);
        console.log(basket);
        setModalProduct({ ...modalProduct, count: modalProduct?.count + 1 });
    }

    return (
        <Transition in={modalProductOpen} timeout={200}>
            {(state) => (
                <Modal
                    keepMounted
                    open={!['exited', 'exiting'].includes(state)}
                    onClose={() => setModalProductOpen(false)}
                    slotProps={{
                        backdrop: {
                            sx: {
                            opacity: 0,
                            backdropFilter: 'none',
                            transition: `opacity 200ms, backdrop-filter 500ms`,
                            ...{
                                entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                                entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                            }[state],
                            },
                        },
                    }}
                    sx={{
                        visibility: state === 'exited' ? 'hidden' : 'visible',
                    }}
                >
                    <ModalOverflow sx={{ height: 'auto' }}>
                        <ModalDialog
                            color="primary"
                            variant="plain"
                            sx={(theme) => ({
                                width: 600,
                                pt: 6,
                                [theme.breakpoints.down('sm')]: {
                                    px: 1,
                                    pb: 1,
                                },
                                borderRadius: 'xl',
                                opacity: 0,
                                transition: `opacity 200ms`,
                                ...{
                                entering: { opacity: 1 },
                                entered: { opacity: 1 },
                                }[state],
                            })}
                        >
                            <ModalClose style={{ zIndex: 2 }} />
                            <DialogContent>
                                <Stack
                                    direction={'column'}
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{ height: '100%' }}
                                >
                                    <SliderDemonstProduct photos={modalProduct?.product?.photos_product} />
                                    {/* <AspectRatio ratio="4/4" sx={{ width: '100%', borderRadius: 'xl',
                                        '& > .MuiAspectRatio-content': { 
                                            ...( image === '' && { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, height: '177px' }
                                        )}
                                    }}>
                                        { image == '' ?
                                        <Box />
                                        :
                                        <Skeleton loading={false} variant="overlay">
                                            <img style={{ borderRadius: 'xl' }} 
                                            width={"100%"} 
                                            src={ image == undefined ? '/default.png' : image }
                                            loading="lazy" alt=""/>
                                        </Skeleton> 
                                    }
                                    </AspectRatio> */}
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ px: 1 }}>
                                            <Typography 
                                                sx={(theme) => ({
                                                    [theme.breakpoints.down('small')]: {
                                                        fontSize: theme.fontSize.xl2
                                                    }
                                                    })} 
                                                fontWeight={'bold'} 
                                                level="h3"
                                                >
                                                    {modalProduct?.product?.name}
                                            </Typography>
                                            <Link endDecorator={<LaunchRoundedIcon fontSize='lg' sx={{ color: 'primary.500' }}/>} underline="always" color="primary" level="body-md" sx={{ mb: 2 }} onClick={toCompany}>
                                                {modalProduct?.product?.shop?.store_name}
                                            </Link>
                                            <Typography level='body-sm' lineHeight={1.2}>
                                                {modalProduct?.product?.description}
                                            </Typography>
                                            <Box sx={{ mt: 2 }}>
                                                <Stack 
                                                    sx={{ width: '100%' }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Stack
                                                        direction="column"
                                                        justifyContent="center"
                                                        alignItems="flex-start"
                                                        spacing={-0.8}
                                                    >
                                                        <Typography>
                                                            {textTranslate.price}:
                                                        </Typography>
                                                        <Typography sx={(theme) => ({
                                                            px: 0, fontWeight: 'xl', color: theme.vars.palette.primary[700],
                                                            [theme.breakpoints.down('small')]: {
                                                                fontSize: theme.fontSize.xl2
                                                            }
                                                            })} level="h2">
                                                            {Math.ceil(modalProduct?.product?.price)} රු
                                                        </Typography>
                                                    </Stack>

                                                    {
                                                        modalProduct?.count <= 0 ?
                                                            <Button 
                                                                size="sm"
                                                                variant="soft"
                                                                sx={{ fontSize: { xs: 'sm', sm: 'md' }, width: "auto", lineHeight: 1 }}
                                                                onClick={plusBask}
                                                            >
                                                                {textTranslate.to_basket}
                                                            </Button>
                                                        :
                                                        <Stack
                                                            direction="row"
                                                            spacing={0}
                                                            sx={{
                                                                width: "auto",
                                                                justifyContent: { xs: 'center', sm: 'flex-end' },
                                                                alignItems: "stretch",
                                                            }}
                                                        >
                                                            <IconButton variant="soft" color="primary" size="sm" onClick={minusBask} sx={{ borderEndEndRadius: '0', borderTopRightRadius: '0', width: "auto" }}>
                                                                <RemoveRoundedIcon />
                                                            </IconButton>
                                                            <Sheet variant="soft" color="primary" size="sm"
                                                                sx={(theme) => ({
                                                                    py: 0,
                                                                    fontSize: 'md',
                                                                    px: 1.5,
                                                                    textAlign: "center",
                                                                    height: 'auto',
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    width: "auto",
                                                                    // backgroundColor: "primary.800",
                                                                    borderRadius: '0',
                                                                    [theme.breakpoints.down('sm')]: {
                                                                        // width: 'auto'
                                                                    }
                                                                })}
                                                            >
                                                                {modalProduct?.count}
                                                            </Sheet>
                                                            <IconButton variant="soft" color="primary" size="sm" onClick={plusBask} sx={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', width: "auto" }}>
                                                                <AddRoundedIcon />
                                                            </IconButton>
                                                        </Stack>
                                                    }
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Stack>
                            </DialogContent>
                        </ModalDialog>
                    </ModalOverflow>
                </Modal>
            )}
        </Transition>
    )
}

export default ModalProduct;