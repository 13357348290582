import React, { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Autoplay, Pagination } from 'swiper/modules';
import { Card, CardContent, CardCover, Chip, Divider, Typography } from '@mui/joy';

import { PhotoProvider, PhotoView } from 'react-photo-view';

import 'react-photo-view/dist/react-photo-view.css';
import zIndex from '@mui/material/styles/zIndex';


export default function SliderDemonstProduct({ photos = [] }) {
    return (
        <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                stretch: 10,
                slideShadows: false,
            }}
            // autoplay={{
            //     delay: 5000
            // }}
            pagination={{
                dynamicBullets: true,
            }}
            modules={[ EffectCoverflow, Pagination ]}
            className="SwiperProductPhoto"
            style={{
                borderRadius: '20px'
            }}
        >
            { 
                photos?.map((item, index) => ( 
                    <SwiperSlide style={{ width: '100%' }} key={item.id}>
                        <Card component="li" sx={{ width: 'auto', minHeight: 220, flexGrow: 1, borderRadius: '20px', borderColor: 'none' }} variant="plain">
                            <CardCover sx={{ borderRadius: '0', display: 'flex' }}>
                                <PhotoProvider>
                                    <PhotoView key={index} src={item.photo} style={{ zIndex: 999, fontFamily: '"Jost", sans-serif', width: '100%', height: '100%' }}>
                                        <img src={item.photo} style={{ cursor: "pointer", objectFit: 'cover', width: '100%', objectFit: 'cover' }}/>
                                    </PhotoView>
                                </PhotoProvider>
                            </CardCover>
                            {/* <CardCover
                                sx={{ background: 'linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 300px)' }}
                            /> */}
                        </Card>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}