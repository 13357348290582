import React, { useContext, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode } from 'swiper/modules';
import { Chip } from '@mui/joy';
import ChipSliderItem from './ChipSliderItem';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutContext } from '../../context';


export default function ChipSlider({ categoryID, typeGroups = [] }) {
    const { textTranslate } = useContext(LayoutContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    // Получение параметра из URL
    const paramSlugGroup = searchParams.get('slugGroup'); // Получаем значение 'param'

    // Альтернативный способ с сохранением других параметров
    const addParamWithoutReset = (value) => {
        if (!value) searchParams.delete('slugGroup'); // Удаляем параметр
        else searchParams.set('slugGroup', value); // Добавляем параметр
        navigate(`?${searchParams.toString()}`, { replace: true });
    };
    
    function ChipItem(title, slug) {
        return <Chip
                    variant={ slug == paramSlugGroup ? "solid" : "plain" }
                    color="neutral"
                    size="lg"
                    onClick={() => addParamWithoutReset(slug)}
                    sx={{
                        "--Chip-minHeight": "35px",
                        "--Chip-radius": "15px",
                        "--Chip-gap": "29px",
                        "--Chip-paddingInline": "20px"
                    }}
                >
                    {title}
                </Chip>
    }



    return (
        <Swiper
            slidesPerView="auto"
            spaceBetween={10}
            freeMode={true}
            style={{ margin: '15px 0', padding: '0 15px', width: 'auto' }}
            modules={[FreeMode]}
            className="ChipSwiper"
        >
            <SwiperSlide style={{ width: 'auto' }}>
                {ChipItem(textTranslate.all, undefined)}
            </SwiperSlide>
            {
                typeGroups.map((item) => ( 
                    <SwiperSlide style={{ width: 'auto' }} key={item?.tag_slug}>
                        {ChipItem(item.name, item.tag_slug)}
                    </SwiperSlide>
                ))
            }
            
        </Swiper>
    )
}